// File: src/components/ProtectedRoute.tsx is a custom component that wraps around a route and checks if the user is authenticated. If the user is authenticated, it renders the component; otherwise, it redirects the user to the login page. This component is used in the App.tsx file to protect routes that require authentication.
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';


const ProtectedRoute: React.FC<{ component: React.ComponentType }> = ({ component: Component }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      console.log("User not authenticated. Redirecting to Auth0 login.");
      loginWithRedirect().then(() => {
        console.log("loginWithRedirect() was called.");
      }).catch((error) => {
        console.error("Error during loginWithRedirect():", error);
      });
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return (
      <div className="flex justify-content-center align-items-center h-screen">
        <ProgressSpinner />
      </div>
    );
  }

  return isAuthenticated ? <Component /> : null;
};

export default ProtectedRoute;

