// File: src/components/test/AgentSelector.tsx
import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Agent } from '../../models/Agent';

interface AgentSelectorProps {
    agents: Agent[];
    selectedAgent: Agent | null;
    onAgentChange: (agent: Agent | null) => void;
}

const AgentSelector: React.FC<AgentSelectorProps> = ({
    agents,
    selectedAgent,
    onAgentChange,
}) => {
    const handleAgentChange = (e: { value: Agent | null }) => {
        onAgentChange(e.value);
    };

    return (
        <Dropdown
            value={selectedAgent}
            options={agents}
            onChange={handleAgentChange}
            optionLabel="agentCompanyName"
            placeholder="Select Agent"
            /* Remove optionValue to ensure e.value is the full Agent object */
        />
    );
};

export default AgentSelector;
