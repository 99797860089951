// File: src/components/ConsoleHeader.tsx
import React from 'react';
import LogoutButton from './LogoutButton';
import config from "../appsettings.json";
const FRONTEND_VERSION = config.FrontendVersion;

export const ConsoleHeader: React.FC = () => (
    <div>
        {/* Header visible only on phones */}
        <div className="flex justify-content-between align-items-center my-1 md:hidden">
            <div className="flex align-items-center">
                <div className="bg-yellow-500 text-black-alpha-90 font-bold border-round m-2 px-2">
                    ANDR3W ADMIN CONSOLE
                </div>
            </div>
            <div className="flex align-items-center">
                <div className="m-2 px-2">
                    <LogoutButton dataAugmentedUi="tr-clip bl-clip" className="max-h-1rem pl-3 pr-3" />
                </div>
            </div>
        </div>

        {/* Header visible on medium and larger screens */}
        <div className="hidden md:flex justify-content-left flex-row my-1">
            <div className="flex flex-wrap">
                <div className="flex align-items-end justify-content-left bg-yellow-500 text-black-alpha-90 font-bold border-round m-2 px-2">
                    ANDR3W ADMIN CONSOLE
                </div>
                <div className="text-xs flex align-items-end justify-content-left bg-yellow-500 text-black-alpha-90 font-bold border-round m-2 px-2">
                    [v{FRONTEND_VERSION}]
                </div>
                <div className="flex align-items-end justify-content-left bg-yellow-500 text-black-alpha-90 font-bold border-round m-2 px-2"></div>
                {/* Additional content for larger screens */}
            </div>
            <div className="flex justify-content-between align-items-center flex-wrap my-0">
                {/* Additional content if needed */}
                <div className="flex justify-content-end m-2 px-2">
                    <LogoutButton dataAugmentedUi="tr-clip bl-clip" className="max-h-1rem pl-3 pr-3" />
                </div>
            </div>
        </div>
    </div>
);

export default ConsoleHeader;