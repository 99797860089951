// src/components/LogoutButton.tsx
import "/node_modules/augmented-ui/augmented-ui.min.css"; // Must be imported before other styles
import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from 'primereact/button';

interface LogoutButtonProps {
  className?: string;
  dataAugmentedUi?: string;
}

const LogoutButton: React.FC<LogoutButtonProps> = ({ className = '', dataAugmentedUi = '' }) => {
  const { logout, isAuthenticated } = useAuth0();

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Button
      data-augmented-ui={dataAugmentedUi}
      label="LOGOUT"
      icon="pi pi-sign-out"
      className={` ${className}`}
      onClick={handleLogout}
    />
  );
};

export default LogoutButton;