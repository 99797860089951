// File: frontend/src/App.tsx
import { setLogLevel } from "@azure/logger";
import { initializeIcons } from "@fluentui/react";
import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useAuth } from './utils/auth';
import CallBoard from "./components/CallBoard";
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
import PrimeReact from "primereact/api";
import { ProgressSpinner } from 'primereact/progressspinner';
import ProtectedRoute from "./components/ProtectedRoute";

PrimeReact.ripple = true;
PrimeReact.inputStyle = "outlined";
PrimeReact.autoZIndex = true;
PrimeReact.appendTo = "self";

setLogLevel("warning");

initializeIcons();
initializeFileTypeIcons();

const webAppTitle = document.title;

// Handle the /auth/callback route to process Auth0's loginWithRedirect() callback
const AuthCallback: React.FC = () => {
  const { handleRedirectCallback, isLoading, error } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const processRedirect = async () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        console.log("Auth0 callback received with query params:", location.search);

        // Check if query params exist
        const code = searchParams.get('code');
        const state = searchParams.get('state');
        console.log("Code from query params:", code);
        console.log("State from query params:", state);

        // Ensure the state and code params are present
        if (code && state) {
          console.log("Query params found: code and state");

          // Dynamically set the redirect_uri to the current page
          const redirectUri = window.location.href;
          console.log("Manually passing callback URL to Auth0:", redirectUri);

          // Call Auth0 to handle the redirect
          await handleRedirectCallback(); 
          console.log("Redirect callback handled successfully.");

          // Navigate to the previous page or root after successful login
          navigate((location.state as any)?.returnTo || "/");
        } else {
          console.error("Missing query parameters: code or state");
          throw new Error("Missing query parameters: code or state");
        }
      } catch (err) {
        if (err instanceof Error) {
          console.error("Error handling Auth0 callback:", err.message, err);
        } else {
          console.error("Unknown error handling Auth0 callback:", err);
        }
      }
    };

    processRedirect();
  }, [handleRedirectCallback, navigate, location]);

  if (isLoading) {
    return (
      <div className="flex justify-content-center align-items-center h-screen">
        <ProgressSpinner />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return <div>Loading...</div>;
};

const AppContent: React.FC = () => {
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const { tenantId, refetchTenantId } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      console.log("User authenticated, fetching tenant ID...");
      refetchTenantId();
    }
  }, [isAuthenticated, refetchTenantId]);

  useEffect(() => {
    console.log("Current tenant ID:", tenantId);
  }, [tenantId]);

  useEffect(() => {
    if (user) {
      console.log("User object:", user);
    }
  }, [user]);

  if (isLoading) {
    return <div className="flex justify-content-center align-items-center h-screen">
      <ProgressSpinner />
    </div>;
  }

  if (error) {
    console.error("AppContent error:", error);
    return <div>Oops... {error.message}</div>;
  }

  return (
    <div className="flex flex-column h-screen space-console">
      <Routes>
        <Route path="/" element={<ProtectedRoute component={() => (<CallBoard tenantId={tenantId} />)} />} />
        <Route path="/auth/callback" element={<AuthCallback />} />
        {/* <Route path="/login" element={<Navigate to="/" replace />} /> */}
      </Routes>
    </div>
  );
};


export default AppContent;