// File: src/components/test/TestStepEditor.tsx

import React, { useState } from 'react';
import { TestConversationStep } from '../../models/TestConversation';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { FloatLabel } from "primereact/floatlabel";

interface TestStepEditorProps {
  steps: TestConversationStep[];
  onStepsChange: (steps: TestConversationStep[]) => void;
}

export const TestStepEditor: React.FC<TestStepEditorProps> = ({ steps, onStepsChange }) => {
  const [localSteps, setLocalSteps] = useState<TestConversationStep[]>([...steps]);

  const handleStepChange = (index: number, field: string, value: string) => {
    const updatedSteps = [...localSteps];
    (updatedSteps[index] as any)[field] = value;
    setLocalSteps(updatedSteps);
    onStepsChange(updatedSteps);
  };

  const addStep = () => {
    setLocalSteps([
      ...localSteps,
      {
        stepNumber: localSteps.length + 1,
        expectedAgentResponse: '',
        humanTestResponse: '',
        notes: '',
      },
    ]);
  };

  const removeStep = (index: number) => {
    const updatedSteps = localSteps.filter((_, i) => i !== index);
    // Re-number the steps
    updatedSteps.forEach((step, i) => {
      step.stepNumber = i + 1;
    });
    setLocalSteps(updatedSteps);
    onStepsChange(updatedSteps);
  };

  return (
    <div>
      <h3>Test Steps</h3>
      {localSteps.map((step, index) => (
        <div key={index} className="p-fluid mb-3">
          <h4 className="mb-6">Step {step.stepNumber}</h4>
          <div className="field">
            <FloatLabel>
              <label htmlFor={`humanTestResponse-${index}`}>Human Response</label>
              <InputTextarea
                id={`humanTestResponse-${index}`}
                value={step.humanTestResponse}
                onChange={(e) => handleStepChange(index, 'humanTestResponse', e.target.value)}
                rows={2}
                cols={30}
              />
            </FloatLabel>
          </div>
          <div className="field mt-4">
            <FloatLabel>
              <label htmlFor={`expectedAgentResponse-${index}`}>Expected Agent Response</label>
              <InputTextarea
                id={`expectedAgentResponse-${index}`}
                value={step.expectedAgentResponse}
                onChange={(e) => handleStepChange(index, 'expectedAgentResponse', e.target.value)}
                rows={2}
                cols={30}
              />
            </FloatLabel>
          </div>

          <div className="field mt-6">
            <FloatLabel>
              <label htmlFor={`notes-${index}`}>Notes</label>
              <InputTextarea
                id={`notes-${index}`}
                value={step.notes}
                onChange={(e) => handleStepChange(index, 'notes', e.target.value)}
                rows={2}
                cols={30}
              />
            </FloatLabel>
          </div>
          <Button
            label="Remove Step"
            icon="pi pi-minus"
            className="p-button-danger w-15rem"
            onClick={() => removeStep(index)}
          />
          <hr />
        </div>
      ))}
      <Button label="Add Step" icon="pi pi-plus" onClick={addStep} />
    </div>
  );
};
