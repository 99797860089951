// CallBoard.tsx
import "/node_modules/augmented-ui/augmented-ui.min.css"; // Must be imported before other styles

import React, { useEffect, useState, useRef, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Agent } from "../models/Agent";
import { useAuth } from '../utils/auth';
import html2canvas from 'html2canvas';
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Slider } from "primereact/slider";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { MeterGroup } from "primereact/metergroup";
import { ToggleButton } from 'primereact/togglebutton';
import { Inplace, InplaceDisplay, InplaceContent } from "primereact/inplace";
import { Tag } from "primereact/tag";
import Chat from "./Chat";
//import { ChatDetailsData, getChatDetails } from "../utils/ChatClientDetails";
import { getChatDetails, ChatDetailsData } from "../utils/ChatClientDetails";
import { makePhoneCall } from "../utils/MakeCallDetails";
import config from "../appsettings.json";
import { uploadCsv } from "../utils/UploadUtils";
import TestManagementDashboard from './test/TestManagementDashboard';
//import RealTimeMonitoringDashboard from './monitoring/RealTimeMonitoringDashboard';
import TestSearchBar from './test/TestSearchBar'; // Make sure to import TestSearchBar
import { Panel } from "primereact/panel";
import { Fieldset } from "primereact/fieldset";
import { Sidebar } from "primereact/sidebar";

//import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
//import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
//import { Tag } from 'primereact/tag';
import { RadioButton } from "primereact/radiobutton";
import { SelectButton } from 'primereact/selectbutton';
import ConsoleHeader from "./ConsoleHeader";
import LogoutButton from "./LogoutButton";
import AgentSelector from "./test/AgentSelector";


// import "primereact/resources/themes/md-dark-indigo/theme.css";
import "primereact/resources/themes/mdc-dark-indigo/theme.css";
//       import "primereact/resources/themes/viva-dark/theme.css"; // Square switches
//       import "primereact/resources/themes/arya-orange/theme.css";
//    import "primereact/resources/themes/arya-green/theme.css";
//   import "primereact/resources/themes/vela-green/theme.css";
//   import "primereact/resources/themes/bootstrap4-dark-blue/theme.css";
// import "primereact/resources/themes/lara-dark-blue/theme.css";
//   import "primereact/resources/themes/lara-dark-indigo/theme.css";
// import "primereact/resources/themes/vela-blue/theme.css";
//import "primereact/resources/themes/vela-orange/theme.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";

import "../styles/global.css";
import "../styles/App.css";
import { getAgents } from "../services/AgentService";




const BASE_URL = process.env.REACT_APP_API_URL;

if (!BASE_URL) {
  throw new Error('REACT_APP_API_URL is not defined');
}
const FRONTEND_VERSION = config.FrontendVersion;

interface CallBoardProps {
  tenantId: string | null;
}

export default function CallBoard({ tenantId }: { tenantId: string | null }): JSX.Element {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  //const { getAccessTokenSilently } = useAuth0();
  const [iconsRegistered, setIconsRegistered] = useState(false);
  const [newCallStarted, setNewCallStarted] = useState(false);
  const [chatData, setChatData] = useState<ChatDetailsData | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [useDemoMode, setUseDemoMode] = useState(false);
  const [useDemoIvrMode, setUseDemoIvrMode] = useState(false);
  const [callListGroupName, setCallListGroupName] = useState<string>("");
  const [answerPromptSystemTemplate, setAnswerPromptSystemTemplate] =
    useState<string>("");
  const [selectedVoice, setSelectedVoice] =
    useState<string>("en-US-KaiNeural"); //en-US-KaiNeural (Works) //en-US-AndrewMultilingualNeural (8565) // en-US-AndrewNeural (8565)
  const [userId, setUserId] = useState<string>("");
  const [threadId, setThreadId] = useState<string>("");
  const [aiName, setAiName] = useState<string>("Andrew");
  const [enableChatWindow, setEnableChatWindow] = useState<boolean>(true);
  const [enableStreamedResponses, setEnableStreamedResponses] =
    useState<boolean>(true);
  const [targetCompanyName, setTargetCompanyName] =
    useState<string>("Sound Construction");
  const [isCalling, setIsCalling] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const [azureOpenAiDeploymentName, setAzureOpenAiDeploymentName] =
    useState<string>(""); //chatgpt-deployment-16k
  const [waitForHelloMs, setWaitForHelloMs] = useState<number>(60000);
  const [initialSilenceTimeoutMs, setInitialSilenceTimeoutMs] =
    useState<number>(20000);
  const [speechPauseTimeoutMs, setSpeechPauseTimeoutMs] = useState<number>(500);
  const [callData, setCallData] = useState<CallData[]>([]);
  const [visible, setVisible] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  // selectedDate should be the current data at the beggining of the day! LIke 12:00 AM
  const [selectedDate, setSelectedDate] = useState<Date>(new Date(new Date().setHours(0, 0, 0, 0)));
  const [calledByDate, setCalledByDate] = useState<Date | null>(null);
  const [targetResponseCount, setTargetResponseCount] = useState<number>(4);
  const [answeredByIVR, setAnsweredByIVR] = useState<boolean>(false);
  const [includeOutboundCalls, setIncludeOutboundCalls] =
    useState<boolean>(true);
  const [includeInboundCalls, setIncludeInboundCalls] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const isFirstLoad = useRef(true);
  const [isTestDashboardVisible, setIsTestDashboardVisible] = useState(false);
  const [isMonitoringDashboardVisible, setIsMonitoringDashboardVisible] = useState(false);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');


  const handleSearch = (search: string) => {
    setSearchTerm(search);
  };


  // Function to count inbound and outbound calls
  const countInboundCalls = () =>
    callData.filter((data) => data.callType === "Inbound").length;
  const countOutboundCalls = () =>
    callData.filter((data) => data.callType === "Outbound").length;

  const UPLOADCSV_API = `${BASE_URL}/api/uploadcsv?tenantId=${tenantId}`;

  interface CallData {
    detailId: string;
    callListId: string;
    doNotCallList: boolean;
    targetResponseCount: number;
    callDurationSeconds: number;
    targetPhoneNumberE164: string;
    callEndTime: string;
    companyName: string;
    callCount: number;
    industry: string;
    targetAnswered: boolean;
    state: string;
    callResult: string;
    answeredByIVR: boolean;
    callErrors: string;
    callType: string;
    conversationText: string;
  }

  const captureAndCopyToClipboard = async (elementId: string) => {
    try {
      const element = document.getElementById(elementId);
      if (!element) {
        throw new Error('Element not found');
      }

      const canvas = await html2canvas(element, {
        backgroundColor: getComputedStyle(element).backgroundColor,
        useCORS: true,
        scale: 2, // Increase resolution
      });

      canvas.toBlob(async (blob) => {
        if (blob) {
          try {
            await navigator.clipboard.write([
              new ClipboardItem({
                'image/png': blob
              })
            ]);
            toastRef.current?.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Call details copied to clipboard as image',
              life: 3000
            });
          } catch (err) {
            console.error('Failed to copy image: ', err);
            toastRef.current?.show({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to copy image to clipboard',
              life: 3000
            });
          }
        }
      }, 'image/png');

    } catch (error) {
      console.error('Error capturing screen:', error);
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to capture call details',
        life: 3000
      });
    }
  };

  // Needed to tie chat window to make call api
  const fetchChatData = useCallback(async () => {
    if (!tenantId) return;

    try {
      const getAccessToken = async () => {
        return await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: "openid profile email"
          }
        });
      };

      const apiData = await getChatDetails(false, tenantId, getAccessToken);
      setChatData(apiData);
      setUserId(apiData.identity);
      setThreadId(apiData.threadId);
      setFirstLoad(false);
    } catch (error) {
      console.error("Error fetching chat data:", error);
      if (error instanceof Error && error.message.includes("401")) {
        // Unauthorized error, user needs to login again
        loginWithRedirect();
      } else {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to fetch chat data. Please try again later.',
          life: 3000,
        });
      }
    }
  }, [tenantId, getAccessTokenSilently, loginWithRedirect]);

  useEffect(() => {
    if (tenantId && firstLoad) {
      fetchChatData();
    }
  }, [tenantId, firstLoad, fetchChatData]);

  // Fetch agents on component mount
  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: 'openid profile email',
          },
        });
        const agentsData = await getAgents(accessToken);
        setAgents(agentsData);
      } catch (error) {
        console.error('Failed to fetch agents:', error);
        // Handle error, e.g., show a toast
      }
    };

    fetchAgents();
  }, [getAccessTokenSilently]);

  // Load saved phone number from localStorage when the component mounts
  useEffect(() => {
    const savedPhoneNumber = localStorage.getItem('phoneNumber');
    if (savedPhoneNumber) {
      setPhoneNumber(savedPhoneNumber);
    }
  }, []);

  // Save phone number to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('phoneNumber', phoneNumber);
  }, [phoneNumber]);

  const handleFetchCallData = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 8000);

    if (!tenantId) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Tenant ID is required to fetch important call data.',
        life: 3000
      });
      setLoading(false);
      return;
    }



    const calledAfterDate = selectedDate.toISOString();
    const calledByDateString = calledByDate ? calledByDate.toISOString() : null;
    try {
      const query = new URLSearchParams({
        calledAfterDate,
        targetResponseCount: targetResponseCount.toString(),
        answeredByIVR: answeredByIVR ? "true" : "false",
        includeOutboundCalls: includeOutboundCalls ? "true" : "false",
        includeInboundCalls: includeInboundCalls ? "true" : "false",
      });

      if (calledByDateString) {
        query.append("calledByDate", calledByDateString);
      }

      console.log("Fetching call data with params:", query.toString());

      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "openid profile email"
        }
      });

      const response = await fetch(
        `${BASE_URL}/api/calldata?${query.toString()}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
          },
        },
      );

      if (!response.ok) {
        const errorDetail = await response.text();
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: errorDetail || 'Failed to fetch call data',
          life: 3000
        });
        throw new Error(errorDetail || "Failed to fetch call data");
      }

      const data = await response.json();
      setCallData(data);
      setVisible(true);
    } catch (error) {
      console.error("Error fetching important call data:", error);
    } finally {
      setLoading(false);
    }
  };


  const toastRef = useRef<Toast>(null);
  const fileUploadRef = useRef<FileUpload>(null);


  const handleUploadCsv = async (event: { files: any[] }) => {
    console.log("handleUploadCsv called");
    setLoading(true);

    if (!tenantId) {
      console.error("Tenant ID is not available");
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Tenant ID is required for CSV upload',
        life: 3000
      });
      setLoading(false);
      return;
    }

    if (event.files.length) {
      const file = event.files[0];
      console.log("Uploading file:", file);
      console.log("Tenant ID:", tenantId);
      console.log("Call List Group Name:", callListGroupName || "");
      console.log("Upload Error Handle Option:", uploadErrorHandleOption);

      try {
        const getAccessToken = async () => {
          return await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE,
              scope: "openid profile email"
            }
          });
        };

        const uploadResult = await uploadCsv(file, tenantId, callListGroupName || null, uploadErrorHandleOption, getAccessToken);

        console.log("Upload Result:", uploadResult);

        if (uploadResult.success) {
          console.log("Showing success toast");
          toastRef.current?.show({
            sticky: true,
            severity: "success",
            summary: "Success",
            detail: `CSV upload results: ${uploadResult.result.message}`,
          });

          if (fileUploadRef.current) {
            fileUploadRef.current.clear();
          }
        } else {
          console.log("Showing error toast");
          toastRef.current?.show({
            sticky: true,
            severity: "error",
            summary: "Error",
            detail: `Upload failed: ${uploadResult.errorMessage}`,
          });
        }
      } catch (err) {
        console.error("Error during file upload:", err);
        toastRef.current?.show({
          sticky: true,
          severity: "error",
          summary: "Error",
          detail: `Upload failed: ${(err as Error).message}`,
        });
      } finally {
        setLoading(false);
      }
    } else {
      console.log("No file selected for upload");
      setLoading(false);
    }
  };




  const [uploadErrorHandleOption, setUploadErrorHandleOption] = useState('AbortOnInvalidFieldData');

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPhoneNumber(event.target.value);
  };

  const handleCallListGroupNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCallListGroupName(event.target.value);
  };

  const handleAzureOpenAiDeploymentChange = async (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const newDeploymentName = event.target.value;
    setAzureOpenAiDeploymentName(newDeploymentName);
  };

  const handleVoiceChange = async (event: { target: { value: string } }) => {
    const newVoice = event.target.value;
    setSelectedVoice(newVoice);

    const voiceParts = newVoice.split("-");
    const aiNameFromVoice =
      voiceParts.length > 2
        ? voiceParts[2].replace("Neural", "").replace("Multilingual", "").replace("Kai", "Andrew")
        : "AI";
    setAiName(aiNameFromVoice);

    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "openid profile email"
        }
      });

      const response = await fetch(
        `${BASE_URL}/api/texttospeechvoice?tenantId=${tenantId}&userId=${chatData?.identity}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
          },
          body: JSON.stringify(newVoice),
        },
      );

      if (!response.ok) {
        throw new Error(
          `Failed to update textToSpeechVoice. Status: ${response.status}`,
        );
      }
    } catch (error) {
      console.error("Failed to update textToSpeechVoice, Error: ", error);
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to update textToSpeechVoice.',
        life: 3000
      });
    }
  };

  const handleAiNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAiName(event.target.value);
  };

  const handleTargetCompanyNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTargetCompanyName(event.target.value);
  };

  const handleRefresh = async () => {
    await handleFetchCallData();
  };

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleCall = async () => {
    console.log("handleCall invoked");
    console.log("Current tenantId:", tenantId);
    console.log("Selected Agent:", selectedAgent);

    setIsCalling(true);
    setNewCallStarted(true);

    if (!tenantId) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Tenant Id is required to start a call',
        life: 3000
      });
      setIsCalling(false);
      return;
    }

    if (!selectedAgent || !selectedAgent.agentAcsPhoneNumberE164) {
      console.log("Agent validation failed:", {
        selectedAgent,
        agentAcsPhoneNumberE164: selectedAgent?.agentAcsPhoneNumberE164,
      });
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please select an Agent with a valid ACS phone number',
        life: 3000
      });
      setIsCalling(false);
      return;
    }

    // **Add this validation for phoneNumber**
    if (!phoneNumber || phoneNumber.trim() === '') {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please enter a target phone number to call in E.164 format',
        life: 3000
      });
      setIsCalling(false);
      return;
    }

    // **Optional: Add phone number format validation**
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    if (!phoneRegex.test(phoneNumber)) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please enter a valid phone number in E.164 format',
        life: 3000
      });
      setIsCalling(false);
      return;
    }

    const acsPhoneNumberE164 = selectedAgent.agentAcsPhoneNumberE164;

    try {
      const getAccessToken = async () => {
        return await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: "openid profile email"
          }
        });
      };

      const accessToken = await getAccessToken();

      const apiData = await getChatDetails(useDemoMode, tenantId, getAccessToken);

      if (apiData) {
        console.log("New chat details fetched successfully:", apiData);
        setChatData(apiData);
        setUserId(apiData.identity);

        const threadId = typeof apiData.threadId === 'object' && apiData.threadId !== null
          ? (apiData.threadId as { value: string }).value
          : apiData.threadId;

        setThreadId(threadId);

        const targetPhoneNumber = phoneNumber; // || "+12066188208";

        console.log("Initiating call with parameters:", {
          acsPhoneNumberE164,
          targetPhoneNumber,
          useDemoMode,
          useDemoIvrMode,
          tenantId,
          identity: apiData.identity,
          threadId,
          token: apiData.token,
          answerPromptSystemTemplate,
          selectedVoice,
          aiName,
          enableChatWindow,
          enableStreamedResponses,
          targetCompanyName,
          azureOpenAiDeploymentName,
          waitForHelloMs,
          initialSilenceTimeoutMs,
          speechPauseTimeoutMs,
          firstName,
          email,
        });

        const response = await makePhoneCall(
          acsPhoneNumberE164, // From selected agent
          targetPhoneNumber,
          useDemoMode,
          useDemoIvrMode,
          tenantId,
          apiData.identity,
          threadId,
          apiData.token,
          answerPromptSystemTemplate,
          selectedVoice,
          aiName,
          enableChatWindow,
          enableStreamedResponses,
          targetCompanyName,
          azureOpenAiDeploymentName,
          waitForHelloMs.toString(),
          initialSilenceTimeoutMs.toString(),
          speechPauseTimeoutMs.toString(),
          firstName,
          email,
          accessToken
        );

        console.log("makePhoneCall response:", response);

        if (response.success) {
          toastRef.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: `Successfully initiated outbound call to: ${targetPhoneNumber}. Call in progress.`,
            life: 5000
          });
        } else {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error',
            detail: `Failed to call phone number: ${targetPhoneNumber}. ${response.errorMessage}`,
            life: 5000
          });
        }
      } else {
        console.error("Error: Chat data is not available");
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to fetch chat details',
          life: 3000
        });
      }
    } catch (error) {
      console.error("Error in handleCall:", error);
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'An unexpected error occurred while making the call',
        life: 5000
      });
    } finally {
      setIsCalling(false);
    }
  };

  const handleCallDetailToggleChange = async (id: string | undefined, field: string, value: boolean, table: 'CallDetails' | 'CallLists') => {
    if (!id) {
      console.error('Invalid ID for toggle change');
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Invalid ID for toggle change',
        life: 3000
      });
      return;
    }

    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "openid profile email"
        }
      });

      const response = await fetch(`${BASE_URL}/api/updatecallfield`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ id, field, value, table }),
      });

      if (!response.ok) {
        throw new Error('Failed to update call field');
      }

      // Update the local state to reflect the change
      setCallData(prevData =>
        prevData.map(call =>
          (table === 'CallDetails' && call.detailId === id) || (table === 'CallLists' && call.callListId === id)
            ? { ...call, [field]: value }
            : call
        )
      );

      // Show success toast
      toastRef.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: `${field} updated successfully`,
        life: 3000
      });
    } catch (error) {
      console.error('Error updating call field:', error);
      // Show error toast
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: `Failed to update ${field}`,
        life: 3000
      });
    }
  };

  const formatConversationText = (text: string) => {
    const personPattern = /(Human:([\s\S]*?))(?=\[|$)/g;
    const AgentPattern = /(Agent:([\s\S]*?))(?=\[|$)/g;
    const objectivePattern = /(\[OBJECTIVE[^\]]*\])/g;

    text = text.replace(personPattern, (match) => {
      return `<strong><span style="color: var(--text-color-person);">${match}</span></strong>`;
    });

    text = text.replace(AgentPattern, (match) => {
      return `<strong><span style="color: var(--text-color-ai);">${match}</span></strong>`;
    });

    text = text.replace(objectivePattern, (match) => {
      return `<span style="margin-left: 0.5rem; color: var(--surface-300); font-weight: 600;">${match}</span>`;
    });

    text = text.replace(
      /\[([^\]]+)\]/g,
      (match) => {
        // Check if it's not an OBJECTIVE bracket
        if (!match.startsWith('[OBJECTIVE')) {
          return `<br/><span style="color: var(--surface-200); font-style: italic; font-size: .75rem;">${match}</span>`;
        }
        return match; // Return OBJECTIVE brackets as-is, they're already colored
      }
    );

    return text;
  };


  // Custom File Uploader /////////////////////////////////////////////////

  //const toast = useRef(null);
  const [totalSize, setTotalSize] = useState(0);
  //const fileUploadRef = useRef(null);

  const onTemplateSelect = (e: { files: any; }) => {
    // First reset to the Empty Template


    setTotalSize(0);
    let _totalSize = totalSize;
    let files = e.files;

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
  };

  // const onTemplateUpload = (e: { files: any[]; }) => {
  //   let _totalSize = 0;

  //   e.files.forEach((file) => {
  //     _totalSize += file.size || 0;
  //   });

  //   setTotalSize(_totalSize);
  //   if (toastRef.current) {
  //     toastRef.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
  //   }
  // };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options: { className: any; chooseButton: any; uploadButton: any; cancelButton: any; }) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 100000;
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

    return (
      <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>{formatedValue} / 5 MB</span>
          <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
        </div>
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column bg-transparent">
        <span className="m-0 p-0">
          Drag and Drop .csv Here and Press Upload
        </span>
        <i className="pi pi-address-book mt-0 py-0 vertical-align-super" style={{ fontSize: '3em', borderRadius: '50%', color: 'var(--sublte-image-color)' }}></i>
      </div>
    );
  };

  const chooseOptions = { icon: 'pi pi-fw pi-address-book', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
  const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
  const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

  //////////////////////////////////////////////////////////////////////////

  const displayCallBoard = (): JSX.Element => {
    return (
      <div className="bg-center bg-no-repeat">
        <ConsoleHeader />
        {/* <ul className="nesting-example" data-augmented-ui="">
              <li data-augmented-ui="" className="nested nesting-left">☢</li>
              <li data-augmented-ui="" className="nested nesting-right">✋</li>
              <li data-augmented-ui="" className="nested nesting-bottom">⛉</li>
              <li data-augmented-ui="" className="nested nesting-top">⚑</li>
            </ul> */}
        <Toast ref={toastRef} />
        <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
        <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
        <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />
        <div className="home-container bg-center bg-no-repeat sm:block md:flex flex-wrap  align-items-center justify-content-center gap-1">
          <div className="flex align-items-center justify-content-center w-28rem h-30rem  m-4">
            <div data-augmented-ui="tl-clip tr-clip-x br-clip-x br-clip-inset b-clip-x bl-clip border" className="common-aug-mixin common-aug-tl-mixin">

              <Card title="Call Controls" className="p-3 card bg-transparent h-30rem">
                <div className="flex flex-wrap gap-3 mt-2">
                  <div style={{ flexGrow: 1, minWidth: '14rem' }}>
                    <Dropdown
                      className="w-15rem"
                      value={selectedVoice}
                      options={[
                        { label: "ANDR3W Neural", value: "en-US-KaiNeural" },
                        { label: "(Good) Ava Neural", value: "en-US-AvaNeural" },
                        { label: "(Good) Emma Neural", value: "en-US-EmmaNeural" },
                        { label: "(Fair) Jason Neural", value: "en-US-JasonNeural" },
                        { label: "(Good) Jenny Neural", value: "en-US-JennyNeural" },
                        { label: "(Good) Luna Neural", value: "en-US-LunaNeural" },
                        { label: "(Good) Nancy Neural", value: "en-US-NancyNeural" },
                        { label: "(Good) NovaTurbo Multilingual Neural", value: "en-US-NovaTurboMultilingualNeural" },

                        { label: "(Bad) AIGenerate1 Neural", value: "en-US-AIGenerate1Neural" },
                        { label: "(Bad) AIGenerate2 Neural", value: "en-US-AIGenerate2Neural" },
                        { label: "(Fail 8565) Adam Multilingual Neural", value: "en-US-AdamMultilingualNeural" },
                        { label: "(Ok) AlloyTurbo Multilingual Neural", value: "en-US-AlloyTurboMultilingualNeural" },
                        { label: "(Ok) Amanda Multilingual Neural", value: "en-US-AmandaMultilingualNeural" },
                        { label: "(Poor) Amber Neural", value: "en-US-AmberNeural" },
                        { label: "(Horrible) Ana Neural (Child)", value: "en-US-AnaNeural" },
                        { label: "(FAIL 8565) Andrew Multilingual Neural", value: "en-US-AndrewMultilingualNeural" },
                        { label: "(FAIL 8565) Andrew Neural", value: "en-US-AndrewNeural" },
                        { label: "(Poor) Aria Neural", value: "en-US-AriaNeural" },
                        { label: "(Poor) Ashley Neural", value: "en-US-AshleyNeural" },
                        { label: "(FAIL 8565) Ava Multilingual Neural", value: "en-US-AvaMultilingualNeural" },
                        { label: "(Poor femle) Blue Neural", value: "en-US-BlueNeural" },
                        { label: "(Bad) Brandon Multilingual Neural", value: "en-US-BrandonMultilingualNeural" },
                        { label: "(Bad) Brandon Neural", value: "en-US-BrandonNeural" },
                        { label: "(Poor) Brian Multilingual Neural", value: "en-US-BrianMultilingualNeural" },
                        { label: "(Poor) Brian Neural", value: "en-US-BrianNeural" },
                        { label: "(Ok) Christopher Multilingual Neural", value: "en-US-ChristopherMultilingualNeural" },
                        { label: "(Ok) Christopher Neural", value: "en-US-ChristopherNeural" },
                        { label: "(Ok) Cora Multilingual Neural", value: "en-US-CoraMultilingualNeural" },
                        { label: "(Ok) Cora Neural", value: "en-US-CoraNeural" },
                        { label: "(Fail 8565) Davis Multilingual Neural", value: "en-US-DavisMultilingualNeural" },
                        { label: "(Ok) Davis Neural", value: "en-US-DavisNeural" },
                        { label: "(Fail 8565) Derek Multilingual Neural", value: "en-US-DerekMultilingualNeural" },
                        { label: "(Bad) Dustin Multilingual Neural", value: "en-US-DustinMultilingualNeural" },
                        { label: "(ok) Elizabeth Neural", value: "en-US-ElizabethNeural" },
                        { label: "(Good) Emma Multilingual Neural", value: "en-US-EmmaMultilingualNeural" },
                        { label: "(Ok) Eric Neural", value: "en-US-EricNeural" },
                        { label: "(Ok) Evelyn Multilingual Neural", value: "en-US-EvelynMultilingualNeural" },
                        { label: "(Ok) Guy Neural", value: "en-US-GuyNeural" },
                        { label: "(Ok) Jacob Neural", value: "en-US-JacobNeural" },
                        { label: "(Fair) Jane Neural", value: "en-US-JaneNeural" },
                        { label: "(Good) Jenny Multilingual Neural", value: "en-US-JennyMultilingualNeural" },
                        { label: "(Ok 8565) Lewis Multilingual Neural", value: "en-US-LewisMultilingualNeural" },
                        { label: "(Ok 8565) Lola Multilingual Neural", value: "en-US-LolaMultilingualNeural" },
                        { label: "(Fair) Michelle Neural", value: "en-US-MichelleNeural" },
                        { label: "(Fair) Monica Neural", value: "en-US-MonicaNeural" },
                        { label: "(Fair) Nancy Multilingual Neural", value: "en-US-NancyMultilingualNeural" },
                        { label: "(Good 8565) Phoebe Multilingual Neural", value: "en-US-PhoebeMultilingualNeural" },
                        { label: "(Poor) Roger Neural", value: "en-US-RogerNeural" },
                        { label: "(Poor) Ryan Multilingual Neural", value: "en-US-RyanMultilingualNeural" },
                        { label: "(Ok) Samuel Multilingual Neural", value: "en-US-SamuelMultilingualNeural" },
                        { label: "(Fair) Sara Neural", value: "en-US-SaraNeural" },
                        { label: "(Ok) Serena Multilingual Neural", value: "en-US-SerenaMultilingualNeural" },
                        { label: "(slow) Steffan Multilingual Neural", value: "en-US-SteffanMultilingualNeural" },
                        { label: "(Slow) Steffan Neural", value: "en-US-SteffanNeural" },
                        { label: "(Bad) Tony Neural", value: "en-US-TonyNeural" },



                      ]}
                      onChange={handleVoiceChange}
                      placeholder="Select Voice"
                    />

                    <InputText
                      className="w-8rem ml-3"
                      type="text"
                      placeholder="AI Name"
                      value={aiName}
                      onChange={handleAiNameChange}
                    />
                  </div>


                  <div className="flex flex-wrap gap-3" >
                    <InputText
                      className="w-17rem"
                      type="tel"
                      placeholder="Phone: +15551234567"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                    />
                    <InputText
                      className="w-9rem"
                      type="text"
                      placeholder="First Name"
                      value={firstName}
                      onChange={handleFirstNameChange}
                    />
                    <InputText
                      className="w-14rem"
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    <div className="mt-3">
                      <InputSwitch
                        checked={enableChatWindow}
                        onChange={(e) => setEnableChatWindow(e.value)}
                      />
                      <span className="vertical-align-top ml-3">Enable Chat Window</span>
                    </div>
                    <AgentSelector
                      agents={agents}
                      selectedAgent={selectedAgent}
                      onAgentChange={setSelectedAgent}
                    />
                  </div>
                  <div className="card flex flex-wrap justify-content-center gap-3">
                    <Button
                      data-augmented-ui="tr-clip bl-clip"
                      label="Make Outbound Test Call"
                      icon="pi pi-android"
                      loading={loading}
                      onClick={handleCall}
                    />
                  </div>
                </div>
              </Card>
            </div>
          </div>

          <div className="flex align-items-center justify-content-center w-28rem h-30rem  m-4">
            <div data-augmented-ui="tr-clip bl-clip-x bl-clip-inset br-clip tr-clip tl-clip-x t-clip-x border" className="common-aug-mixin common-aug-tr-mixin">

              <Card className="p-3 card bg-transparent h-30rem" title={
                <div className="card flex justify-content-end">Test Mode Controls</div>
              }>
                <div className="flex flex-wrap gap-3">
                  <div className="flex align-items-center">
                    <InputSwitch
                      checked={useDemoMode}
                      onChange={(e) => setUseDemoMode(e.value)}
                    />
                    <span className="vertical-align-top ml-2">Demo (mute phone)</span>
                  </div>
                  <div className="flex align-items-center">
                    <InputSwitch
                      checked={useDemoIvrMode}
                      onChange={(e) => setUseDemoIvrMode(e.value)}
                    />
                    <span className="vertical-align-top ml-2">Demo IVR (mute phone)</span>
                  </div>

                  <Button
                    label="Open Test Dashboard"
                    icon="pi pi-table"
                    onClick={() => setIsTestDashboardVisible(true)}
                  />

                  <Button
                    label="Open Monitoring Dashboard"
                    icon="pi pi-chart-line"
                    onClick={() => setIsMonitoringDashboardVisible(true)}
                  />

                </div>


              </Card>
            </div>
          </div>

          <div className="flex align-items-center justify-content-center w-28rem h-30rem  m-4">
            <div data-augmented-ui="tl-clip bl-clip br-clip tr-clip-x tr-clip-inset t-clip-x border" className="common-aug-mixin common-aug-bl-mixin">
              <Card title="Upload CSV" className="p-3 card bg-transparent h-30rem">
                <label className="ml-1">On csv invalid data import errors?</label>
                <div className="card flex justify-content-left mt-2">
                  <div className="flex flex-wrap gap-3">
                    <div className="flex align-items-center">
                      <RadioButton inputId="AbortOnInvalidFieldData" name="AbortOnInvalidFieldData" value="AbortOnInvalidFieldData" onChange={(e) => setUploadErrorHandleOption(e.value)} checked={uploadErrorHandleOption === 'AbortOnInvalidFieldData'} />
                      <label htmlFor="uploadErrorHandle1" className="ml-2">Abort Upload</label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton inputId="SkipRowsWithInvalidFieldData" name="SkipRowsWithInvalidFieldData" value="SkipRowsWithInvalidFieldData" onChange={(e) => setUploadErrorHandleOption(e.value)} checked={uploadErrorHandleOption === 'SkipRowsWithInvalidFieldData'} />
                      <label htmlFor="uploadErrorHandle2" className="ml-2">Skip Row</label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton inputId="ExcludeInvalidFieldData" name="ExcludeInvalidFieldData" value="ExcludeInvalidFieldData" onChange={(e) => setUploadErrorHandleOption(e.value)} checked={uploadErrorHandleOption === 'ExcludeInvalidFieldData'} />
                      <label htmlFor="uploadErrorHandle3" className="ml-2">Exclude Field</label>
                    </div>
                  </div>
                </div>
                <InputText className="mt-3"
                  type="text"
                  placeholder="Call List Group Name"
                  value={callListGroupName}
                  onChange={handleCallListGroupNameChange}
                />
                <FileUpload
                  ref={fileUploadRef}
                  name="csvupload"
                  url={UPLOADCSV_API}
                  accept=".csv"
                  maxFileSize={5000000}
                  uploadHandler={handleUploadCsv}
                  customUpload
                  multiple={false}
                  disabled={loading || !tenantId} // Disable if loading or no tenantId
                  onSelect={onTemplateSelect}
                  onError={onTemplateClear}
                  onClear={onTemplateClear}
                  headerTemplate={headerTemplate}
                  emptyTemplate={emptyTemplate}
                  chooseOptions={chooseOptions}
                  uploadOptions={uploadOptions}
                  cancelOptions={cancelOptions}
                  className="bg-black-alpha-10 mt-3"
                />
              </Card>
            </div>
          </div>

          <div className="flex align-items-center justify-content-center w-28rem h-30rem  m-4">
            <div data-augmented-ui="tr-clip bl-clip br-clip tr-clip tl-clip-x tl-clip-inset b-clip border" className="common-aug-mixin common-aug-br-mixin">
              <Card className="p-3 card bg-transparent h-30rem w-28rem" title={
                <div className="card flex justify-content-end">Settings</div>
              }>
                <div className="mt-2 mb-4">
                  <InputSwitch
                    checked={enableStreamedResponses}
                    onChange={(e) => setEnableStreamedResponses(e.value)}
                  />
                  <span className="vertical-align-top ml-3">Fast Stream Response</span>
                </div>
                <label>Hello Wait (ms): {waitForHelloMs}</label>
                <Slider
                  className="mb-4"
                  value={waitForHelloMs}
                  onChange={(e) => setWaitForHelloMs(e.value as number)}
                  min={0}
                  max={60000}
                />
                <label>Silence Timeout: {initialSilenceTimeoutMs}</label>
                <Slider
                  className="mb-4"
                  value={initialSilenceTimeoutMs}
                  onChange={(e) =>
                    setInitialSilenceTimeoutMs(e.value as number)
                  }
                  min={0}
                  max={20000}
                />
                <label>Done Speaking: {speechPauseTimeoutMs}</label>
                <Slider
                  value={speechPauseTimeoutMs}
                  onChange={(e) => setSpeechPauseTimeoutMs(e.value as number)}
                  min={100}
                  max={5000}
                />
                <InputText
                  className="mt-3"
                  type="text"
                  placeholder="Target Company Name"
                  value={targetCompanyName}
                  onChange={handleTargetCompanyNameChange}
                />
                {/* <InputText
                  type="text"
                  value={tenantId}
                  onChange={(e) => setTenantId(e.target.value)}
                  placeholder="Enter Tenant ID"
                /> */}
              </Card>
            </div>
          </div>
        </div>

        <div className="flex justify-content-center flex-wrap gap-3">





          <div className="card flex justify-content-center">
            <Sidebar
              visible={visible}
              onHide={() => setVisible(false)}
              fullScreen
              header={
                <div className="card flex flex-wrap gap-3">
                  <div>
                    Call Data:
                    <br />
                    <span className="text-sm">
                      <span className="font-bold">
                        [ {callData.length} calls ]
                      </span>
                    </span>
                    <br />
                    <span className="text-sm">
                      <span className="font-bold">
                        {countInboundCalls()} inbound calls
                      </span>
                    </span>
                    <br />
                    <span className="text-sm">
                      <span className="font-bold">
                        {countOutboundCalls()} outbound calls
                      </span>
                    </span>
                  </div>
                  <MeterGroup
                    values={[
                      {
                        label: "Total Calls",
                        color: "#34d399",
                        value: callData.length,
                      },
                      {
                        label: "Inbound Calls",
                        color: "#60a5fa",
                        value: countInboundCalls(),
                      },
                      {
                        label: "Outbound Calls",
                        color: "#fbbf24",
                        value: countOutboundCalls(),
                      },
                    ]}
                    max={callData.length}
                  />
                  <Button
                    icon="pi-refresh"
                    label="Refresh"
                    onClick={handleRefresh}
                  />
                  <div className="p-grid p-align-center p-justify-start gap-3">
                    <Tag
                      className="mr-2"
                      value={`Response Count >= ${targetResponseCount}`}
                      severity={
                        targetResponseCount !== 0 ? "success" : "warning"
                      }
                    />
                    <Tag
                      className="mr-2"
                      value={`IVR Answered: ${answeredByIVR ? "Yes" : "No"}`}
                      severity={answeredByIVR ? "success" : "warning"}
                    />
                    <Tag
                      className="mr-2"
                      value={`Outbound Calls: ${includeOutboundCalls ? "Yes" : "No"}`}
                      severity={includeOutboundCalls ? "success" : "warning"}
                    />
                    <Tag
                      className="mr-2"
                      value={`Inbound Calls: ${includeInboundCalls ? "Yes" : "No"}`}
                      severity={includeInboundCalls ? "success" : "warning"}
                    />
                    <Tag
                      className="mr-2"
                      value={`Called After: ${selectedDate.toISOString()}`}
                      severity={selectedDate ? "success" : "warning"}
                    />
                    {calledByDate && (
                      <Tag
                        className="mr-2"
                        value={`Called Before: ${calledByDate.toISOString()}`}
                        severity={calledByDate ? "success" : "warning"}
                      />
                    )}
                  </div>
                </div>
              }
            >
              <div className="call-data-body">
                {callData.length > 0 ? (
                  callData.map((data, index) => (
                    <div key={index} className="call-data-item" id={`call-data-${index}`}>
                      <div className="call-data-content">

                        <br />
                        <strong>Company Name:</strong> <span className='text-4xl ml-3'>{data.companyName}</span>
                        <span className='text-2xl text-yellow-300 ml-3'>{data.targetPhoneNumberE164}</span>
                        <span className='ml-3 uppercase'>{data.callType}</span>
                        <Fieldset
                          className="mt-3"
                          legend="Call Details"
                          toggleable
                        >
                          <strong>Target Response Count:</strong>{" "}
                          {data.targetResponseCount}
                          <br />
                          <strong>Call Duration:</strong>{" "}
                          {data.callDurationSeconds} seconds
                          <br />
                          <strong>Call End Time:</strong> {data.callEndTime}
                          <br />
                          <strong>Call Count:</strong> {data.callCount}
                          <br />
                          <strong>Industry:</strong> {data.industry}
                          <br />
                          <strong>State:</strong> {data.state}
                          <br />
                          <div className="flex align-items-center my-2">

                            <label className="mr-2">IVR Answered:</label>
                            <ToggleButton
                              checked={data.answeredByIVR}
                              onChange={(e) => handleCallDetailToggleChange(data.detailId, 'answeredByIVR', e.value, 'CallDetails')}
                              onLabel="Yes"
                              offLabel="No"
                              className="ml-2 h-1rem w-auto"
                            />
                          </div>
                          <div className="flex align-items-center my-2">
                            <label className="mr-2">Person Answered:</label>
                            <ToggleButton
                              checked={data.targetAnswered}
                              onChange={(e) => handleCallDetailToggleChange(data.detailId, 'targetAnswered', e.value, 'CallDetails')}
                              onLabel="Yes"
                              offLabel="No"
                              className="ml-2 h-1rem w-auto"
                            />
                          </div>
                          <div className="flex align-items-center my-2">
                            <label className="mr-2">Do Not Call List:</label>
                            <ToggleButton
                              checked={data.doNotCallList}
                              onChange={(e) => handleCallDetailToggleChange(data.callListId, 'doNotCallList', e.value, 'CallLists')}
                              onLabel="Yes"
                              offLabel="No"
                              className="ml-2 h-1rem w-auto"
                            />
                          </div>
                          <br />
                          <strong>Call Result:</strong> {data.callResult}
                          {data.callErrors && data.callErrors.length > 0 && (
                            <div>
                              <Inplace className="mt-1 text-red-500 text-xs">
                                <InplaceDisplay>+ View Call Errors...</InplaceDisplay>
                                <InplaceContent>
                                  <strong>Call Errors:</strong> {data.callErrors}
                                </InplaceContent>
                              </Inplace>
                            </div>
                          )}
                        </Fieldset>
                        <p>
                          <strong>Conversation Text:</strong>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: formatConversationText(
                                data.conversationText,
                              ),
                            }}
                          />
                        </p>
                      </div>
                      <Button
                        icon="pi pi-copy"
                        onClick={() => captureAndCopyToClipboard(`call-data-${index}`)}
                        className="p-button-rounded p-button-text"
                        tooltip="Copy call details as image"
                        tooltipOptions={{ position: 'bottom' }}
                      />
                      <hr />
                    </div>
                  ))
                ) : (
                  <p>No call data available for the selected date and time.</p>
                )}
              </div>
            </Sidebar>
          </div>
        </div>
        <div>
          {chatData && (
            <Chat
              {...chatData}
              userId={userId}
              threadId={threadId}
              isOpen={isChatOpen}
              newCallStarted={newCallStarted}
              onChatCleared={() => setNewCallStarted(false)}
            />
          )}
        </div>

        <Dialog
          visible={isTestDashboardVisible}
          onHide={() => setIsTestDashboardVisible(false)}
          modal
          header={
            <div className="flex justify-content-between align-items-center w-full">
              <h3 className="m-0">Test Management</h3>
              <div className="w-20rem">
                <TestSearchBar onSearch={handleSearch} />
              </div>
            </div>
          }
          className="w-screen h-screen border-2"
          draggable={false}
          resizable={false}
        >
          <TestManagementDashboard searchTerm={searchTerm} />
        </Dialog>

        <Dialog
          visible={isMonitoringDashboardVisible}
          onHide={() => setIsMonitoringDashboardVisible(false)}
          modal
          header="Real-Time Monitoring"
          className="w-full h-full border-2"
          draggable={false}
          resizable={false}
        >
          {/* <RealTimeMonitoringDashboard /> */}
        </Dialog>



        <div data-augmented-ui="tl-clip br-clip both"></div>
        <div data-augmented-ui="all-triangle-down border"></div>


      </div>
    );

  };

  return displayCallBoard();
};
