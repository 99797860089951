// File: src/components/test/TestManagementDashboard.tsx

import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { TestConversation, TestRunResult } from '../../models/TestConversation';
import { deleteTest, getTestResult, getTestsByAgentId, runTests, createTestFromCallId } from '../../services/TestService';
import { useAuth0 } from '@auth0/auth0-react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';

import { Tag } from 'primereact/tag';
import './TestManagementDashboard.css';
import CreateTestDialog from './CreateTestDialog';
import EditTestDialog from './EditTestDialog';
import AgentSelector from './AgentSelector';
import TestResultDialog from './TestResultDialog';
import { TestConversationResult } from '../../models/TestConversationResult';
import { Agent } from '../../models/Agent';
import { getAgents } from '../../services/AgentService';

interface TestManagementDashboardProps {
    searchTerm: string;
}

const TestManagementDashboard: React.FC<TestManagementDashboardProps> = ({ searchTerm }) => {

    const { getAccessTokenSilently } = useAuth0();
    const [tests, setTests] = useState<TestConversation[]>([]);
    const [selectedTests, setSelectedTests] = useState<TestConversation[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const toast = React.useRef<Toast>(null);
    const [isCreateDialogVisible, setIsCreateDialogVisible] = useState<boolean>(false);
    const [isEditDialogVisible, setIsEditDialogVisible] = useState<boolean>(false);
    const [selectedTest, setSelectedTest] = useState<TestConversation | null>(null);
    const [filteredTests, setFilteredTests] = useState<TestConversation[]>([]);
    const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
    const [isResultDialogVisible, setIsResultDialogVisible] = useState<boolean>(false);
    const [selectedTestResult, setSelectedTestResult] = useState<TestConversationResult | null>(null);
    const [callId, setCallId] = useState<string>('');
    const [loadingCallId, setLoadingCallId] = useState<boolean>(false);
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [agents, setAgents] = useState<Agent[]>([]);

    useEffect(() => {
        filterTests(searchTerm, selectedAgent?.agentId ?? null);
    }, [searchTerm, selectedAgent?.agentId, tests]);

    const fetchTests = async (agentId: string) => {
        setLoading(true);
        try {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                    scope: 'openid profile email',
                },
            });
            const data = await getTestsByAgentId(agentId, accessToken);
            console.log('Fetched tests:', data);
            setTests(data);
            setFilteredTests(data);
            setError(null); // Clear any previous error
        } catch (err) {
            setError('Failed to fetch tests.');
        } finally {
            setLoading(false);
        }
    };

    // Call fetchTests with selectedAgent?.agentId when agent changes
    useEffect(() => {
        if (selectedAgent?.agentId) {
            fetchTests(selectedAgent?.agentId);
        }
    }, [selectedAgent?.agentId]);

    // Load saved phone number from localStorage when the component mounts
    useEffect(() => {
        const savedPhoneNumber = localStorage.getItem('phoneNumber');
        if (savedPhoneNumber) {
            setPhoneNumber(savedPhoneNumber);
        }
    }, []);

    // Save phone number to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('phoneNumber', phoneNumber);
    }, [phoneNumber]);

    useEffect(() => {
        const fetchAgents = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                        scope: 'openid profile email',
                    },
                });
                const agentsData = await getAgents(accessToken);
                setAgents(agentsData);
            } catch (error) {
                console.error('Failed to fetch agents:', error);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to fetch agents. Please try again later.',
                    life: 3000,
                });
            }
        };

        fetchAgents();
    }, [getAccessTokenSilently]);

    const handleDelete = (test: TestConversation) => {
        confirmDialog({
            message: `Are you sure you want to delete test "${test.testName}"?`,
            header: 'Confirm Delete',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                try {
                    const accessToken = await getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                            scope: 'openid profile email',
                        },
                    });
                    await deleteTest(test.testId, accessToken);
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Deleted',
                        detail: `Test "${test.testName}" deleted.`,
                        life: 3000,
                    });
                    fetchTests(selectedAgent?.agentId || '');
                } catch (error) {
                    console.error('Failed to delete test', error);
                    toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Failed to delete test.',
                        life: 3000,
                    });
                }
            },
        });
    };

    const handleEdit = (test: TestConversation) => {
        setSelectedTest(test);
        setIsEditDialogVisible(true);
    };

    const handleViewResult = async (test: TestConversation) => {
        try {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                    scope: 'openid profile email',
                },
            });

            const result = await getTestResult(test.testId, accessToken);
            setSelectedTestResult(result);
            setIsResultDialogVisible(true);
        } catch (error) {
            console.error('Failed to fetch test result', error);
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to fetch test result.',
                life: 3000,
            });
        }
    };

    const filterTests = (search: string, agentId: string | null) => {
        let filtered = tests;

        if (search) {
            filtered = filtered.filter((test) =>
                test.testName.toLowerCase().includes(search.toLowerCase())
            );
        }

        if (agentId && agentId !== '') {
            filtered = filtered.filter((test) => test.agentId === agentId);
        }

        setFilteredTests(filtered);
    };

    const handleAgentFilterChange = (agent: Agent | null) => {
        setSelectedAgent(agent);
        const agentId = agent?.agentId || '';
        fetchTests(agentId);
        filterTests(searchTerm, agentId);
    };

    const statusBodyTemplate = (rowData: TestConversation) => {
        return (
            <Tag
                value={rowData.isActive ? 'Active' : 'Inactive'}
                severity={rowData.isActive ? 'success' : 'warning'}
            />
        );
    };

    const actionBodyTemplate = (rowData: TestConversation) => {
        return (
            <div className="flex gap-2">
                <Button
                    className="p-button-rounded p-button-text"
                    onClick={() => handleEdit(rowData)}
                    label="Edit"
                />
                <Button
                    className="p-button-rounded p-button-text p-button-danger"
                    onClick={() => handleDelete(rowData)}
                    label="Delete"
                />
                <Button
                    className="p-button-rounded p-button-text"
                    onClick={() => handleViewResult(rowData)}
                    label="Results"
                />
            </div>
        );
    };

    // Handler for creating test from Call ID
    const handleCreateTestFromCallId = async () => {
        if (!callId || !selectedAgent?.agentId) {
            toast.current?.show({
                severity: 'warn',
                summary: 'Validation Error',
                detail: 'Please enter a Call ID and select an Agent.',
                life: 3000,
            });
            return;
        }

        setLoadingCallId(true);
        try {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                    scope: 'openid profile email',
                },
            });

            await createTestFromCallId(callId, selectedAgent?.agentId, accessToken);

            toast.current?.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Test created successfully from Call ID.',
                life: 3000,
            });

            // Refresh tests list
            fetchTests(selectedAgent?.agentId || '');
        } catch (error) {
            console.error('Failed to create test from Call ID', error);
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to create test from Call ID.',
                life: 3000,
            });
        } finally {
            setLoadingCallId(false);
        }
    };

    const header = (
        <div className="table-header flex flex-column justify-content-between">
            <div className="flex justify-content-between flex-wrap mt-3">
                <div className="flex gap-3">
                    <AgentSelector
                        agents={agents}
                        selectedAgent={selectedAgent}
                        onAgentChange={setSelectedAgent}
                    />
                    <InputText
                        value={callId}
                        onChange={(e) => setCallId(e.target.value)}
                        placeholder="Enter Call ID"
                        style={{ width: '200px' }}
                    />
                    <Button
                        label="Create from Call ID"
                        icon="pi pi-plus"
                        onClick={handleCreateTestFromCallId}
                        loading={loadingCallId}
                        disabled={!callId || !selectedAgent?.agentId}
                    />
                </div>
            </div>
        </div>
    );

    const confirmDeleteSelected = () => {
        confirmDialog({
            message: 'Are you sure you want to delete the selected tests?',
            header: 'Confirm Delete',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteSelectedTests(),
        });
    };

    const deleteSelectedTests = async () => {
        try {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                    scope: 'openid profile email',
                },
            });

            for (const test of selectedTests) {
                await deleteTest(test.testId, accessToken);
            }

            toast.current?.show({
                severity: 'success',
                summary: 'Deleted',
                detail: `${selectedTests.length} tests deleted.`,
                life: 3000,
            });

            setSelectedTests([]);
            fetchTests(selectedAgent?.agentId || '');
        } catch (error) {
            console.error('Failed to delete tests', error);
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to delete selected tests.',
                life: 3000,
            });
        }
    };

    const handleRunSelected = async () => {
        if (!phoneNumber) {
            toast.current?.show({
                severity: 'warn',
                summary: 'Validation Error',
                detail: 'Please enter a phone number.',
                life: 3000,
            });
            return;
        }

        try {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                    scope: 'openid profile email',
                },
            });

            const testIds = selectedTests.map((test) => test.testId);

            await runTests(testIds, phoneNumber, accessToken);

            toast.current?.show({
                severity: 'success',
                summary: 'Tests Running',
                detail: `Started running ${selectedTests.length} tests.`,
                life: 3000,
            });

            setSelectedTests([]);
        } catch (error) {
            console.error('Failed to run tests', error);
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to run selected tests.',
                life: 3000,
            });
        }
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-3">
                <InputText
                    className="w-17rem"
                    type="tel"
                    placeholder="Phone: +15551234567"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <Button
                    label="Run"
                    icon="pi pi-play"
                    className="p-button-success mr-2"
                    onClick={handleRunSelected}
                    disabled={!selectedTests || selectedTests.length === 0 || !phoneNumber}
                />
                <Button
                    label="Delete"
                    icon="pi pi-trash"
                    className="p-button-danger"
                    onClick={confirmDeleteSelected}
                    disabled={!selectedTests || selectedTests.length === 0}
                />
                <Button
                    label="New Test"
                    icon="pi pi-plus"
                    className="p-button-success"
                    onClick={() => setIsCreateDialogVisible(true)}
                    disabled={!selectedAgent?.agentId}
                />
            </div>
        );
    };

    // Add a right toolbar template with a refresh button
    const rightToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-3">
                <Button
                    label="Refresh"
                    icon="pi pi-refresh"
                    className="p-button-secondary"
                    onClick={handleRefresh}
                />
            </div>
        );
    };

    // Handler for refresh button
    const handleRefresh = () => {
        fetchTests(selectedAgent?.agentId || '');
    };

    const dateBodyTemplate = (rowData: TestConversation) => {
        return rowData.lastRunDateTimeUTC
            ? new Date(rowData.lastRunDateTimeUTC).toLocaleString()
            : 'Never';
    };

    const resultBodyTemplate = (rowData: TestConversation) => {
        const result = rowData.lastRunResult || TestRunResult.NotRun;
        let severity: 'success' | 'info' | 'danger' | 'warning' | null = null;

        switch (result) {
            case TestRunResult.Passed:
                severity = 'success';
                break;
            case TestRunResult.Failed:
                severity = 'danger';
                break;
            case TestRunResult.Running:
                severity = 'warning';
                break;
            default:
                severity = 'info';
                break;
        }

        return <Tag value={result} severity={severity} />;
    };

    return (
        <div className="test-management-dashboard">
            <Toast ref={toast} />
            <ConfirmDialog />
            {error && <p className="error">{error}</p>}
            {loading ? (
                <div className="flex justify-content-center align-items-center h-100">
                    <ProgressSpinner />
                </div>
            ) : (
                <>
                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <DataTable
                        value={filteredTests}
                        className="bg-transparent"
                        selection={selectedTests}
                        onSelectionChange={(e) => setSelectedTests(e.value)}
                        selectionMode="multiple"
                        paginator
                        rows={10}
                        header={header}
                        dataKey="testId"
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                        <Column field="testName" header="Test Name" sortable />
                        <Column field="agentCompanyName" header="Agent" sortable />
                        <Column
                            field="isActive"
                            header="Status"
                            body={statusBodyTemplate}
                            sortable
                        />
                        <Column field="lastModifiedDateTimeUTC" header="Last Modified" />
                        <Column body={actionBodyTemplate} header="Actions" />
                        <Column
                            field="lastRunDateTimeUTC"
                            header="Last Run"
                            body={dateBodyTemplate}
                            sortable
                        />
                        <Column
                            field="lastRunResult"
                            header="Result"
                            body={resultBodyTemplate}
                            sortable
                        />
                    </DataTable>
                </>
            )}

            <CreateTestDialog
                visible={isCreateDialogVisible}
                onHide={() => setIsCreateDialogVisible(false)}
                onTestCreated={() => fetchTests(selectedAgent?.agentId || '')}
            />
            {isEditDialogVisible && selectedTest && (
                <EditTestDialog
                    visible={isEditDialogVisible}
                    onHide={() => setIsEditDialogVisible(false)}
                    testData={selectedTest}
                    onTestUpdated={() => fetchTests(selectedAgent?.agentId || '')}
                />
            )}
            {isResultDialogVisible && selectedTestResult && (
                <TestResultDialog
                    visible={isResultDialogVisible}
                    onHide={() => setIsResultDialogVisible(false)}
                    testResult={selectedTestResult}
                />
            )}
        </div>
    );
};

export default TestManagementDashboard;
