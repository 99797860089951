// UploadUtils.ts
import config from "../appsettings.json";
const BASE_URL = process.env.REACT_APP_API_URL;

if (!BASE_URL) {
  throw new Error('REACT_APP_API_URL is not defined');
}

interface UploadCsvSuccessResult {
  success: true;
  result: { message: string };
}

interface UploadCsvErrorResult {
  success: false;
  errorMessage: string;
}

type UploadCsvResult = UploadCsvSuccessResult | UploadCsvErrorResult;

export const uploadCsv = async (
  file: File,
  tenantId: string,
  callListGroupName: string | null,
  uploadErrorHandleOption: string,
  getAccessToken: () => Promise<string>
): Promise<UploadCsvResult> => {
  console.log("Initiating uploadCsv function");
  console.log("Tenant ID:", tenantId);
  console.log("Call List Group Name:", callListGroupName);
  console.log("Upload Error Handle Option:", uploadErrorHandleOption);

  try {
    const accessToken = await getAccessToken();
    const formData = new FormData();
    formData.append("file", file);
    if (callListGroupName) {
      formData.append("callListGroupName", callListGroupName);
    }
    formData.append("uploadErrorHandleOption", uploadErrorHandleOption);

    console.log("Sending request to upload CSV");

    const response = await fetch(
      `${BASE_URL}/api/uploadcsv?tenantId=${tenantId}`,
      {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${accessToken}`
        },
        body: formData,
      }
    );

    if (!response.ok) {
      console.error("Upload CSV request failed with status:", response.status); // Debug log
      let errorMessage = "Unknown error occurred";
      try {
        const errorData = await response.json();
        errorMessage = errorData.errorMessage || errorMessage;
        console.error("Error response from server:", errorData); // Debug log
      } catch (jsonError) {
        console.error("Failed to parse error response as JSON:", jsonError);
      }
      return {
        success: false,
        errorMessage,
      };
    }

    const result = await response.json();
    console.log("Upload CSV successful:", result); // Debug log
    return { success: true, result: result };
  } catch (err) {
    console.error("Error in uploadCsv:", err);
    return {
      success: false,
      errorMessage: `An error occurred while uploading the CSV: ${(err as Error).message}`,
    };
  }
};

