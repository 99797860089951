// File: src/services/AgentService.ts

import { Agent } from '../models/Agent';

export const getAgents = async (accessToken: string): Promise<Agent[]> => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  
  console.log('API Base URL:', API_BASE_URL);
  console.log('Access Token (partial):', accessToken ? `${accessToken.substring(0, 10)}...` : 'No Token');
  console.log('Requesting:', `${API_BASE_URL}/api/agent/tenantagents`);

  const response = await fetch(`${API_BASE_URL}/api/agent/tenantagents`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    const errorBody = await response.text();
    console.error(`Failed to fetch agents. Status: ${response.status}, Response: ${errorBody}`);
    throw new Error(`Failed to fetch agents. Status: ${response.status}`);
  }

  const data = await response.json();
  console.log('Agents fetched successfully:', data);
  return data;
};

