// TestResultDialog.tsx
import React from 'react';
import { Dialog } from 'primereact/dialog';
import { TestConversationResult } from '../../models/TestConversationResult';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Divider } from 'primereact/divider';
import 'primeflex/primeflex.css';
import './TestResultDialog.css';
import { Tag } from 'primereact/tag';

interface TestResultDialogProps {
  visible: boolean;
  onHide: () => void;
  testResult: TestConversationResult | null;
}

// Define the MessageData and ConversationJsonData interfaces
interface MessageData {
  Role: string;
  Content: string;
  Timestamp: string;
  Tag: string;
  DurationMs: number;
  RelatedObjectiveId: string | null;
  AiGenDurationMs: number;
  IsMessageReceived: boolean;
  OperationContextJson: string;
}

interface ConversationJsonData {
  ConversationId: string;
  Messages: MessageData[];
}

const TestResultDialog: React.FC<TestResultDialogProps> = ({
  visible,
  onHide,
  testResult,
}) => {
  if (!testResult) return null;

  // Parse the conversation
  const parseConversation = (
    testConversationJson: string | null | undefined
  ): MessageData[] => {
    if (!testConversationJson) return [];
    try {
      const conversationData: ConversationJsonData = JSON.parse(testConversationJson);
      if (!conversationData || !Array.isArray(conversationData.Messages)) {
        console.error('ConversationData.Messages is not an array');
        return [];
      }
      return conversationData.Messages;
    } catch (e) {
      console.error('Failed to parse conversation JSON', e);
      return [];
    }
  };

  const conversation = parseConversation(testResult.testConversationJson);

  // Check if conversation is an array
  if (!Array.isArray(conversation)) {
    console.error('Parsed conversation is not an array');
    return null;
  }

  // Group messages by sender
  const messages = conversation;
  const groupedMessages = [];
  let currentGroup = { sender: '', messages: [] as MessageData[] };

  for (let i = 0; i < messages.length; i++) {
    const message = messages[i];
    const sender = message.Role;
    if (currentGroup.sender !== sender) {
      if (currentGroup.messages.length > 0) {
        groupedMessages.push({ ...currentGroup });
      }
      currentGroup = { sender, messages: [message] };
    } else {
      currentGroup.messages.push(message);
    }
  }
  if (currentGroup.messages.length > 0) {
    groupedMessages.push({ ...currentGroup });
  }

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="Test Result"
      modal
      className="w-screen h-screen"
    >
      <div className="p-fluid">
        <div className="field">
          <p>
            <strong>Run Date:</strong>{' '}
            {new Date(testResult.runDateTimeUTC).toLocaleString()}
          </p>
          <p>
            <strong>Result:</strong>{' '}
            {testResult.isPassed ? (
              <Tag severity="success" value="Passed"></Tag>
            ) : (
              <Tag severity="danger" value="Failed"></Tag>
            )}
          </p>
          {testResult.failureReason && (
            <p>
              <strong>Failure Reason:</strong>{' '}
              <span className="text-red-500">{testResult.failureReason}</span>
            </p>
          )}
          <p>
            <strong>Duration:</strong> {testResult.durationSeconds} seconds
          </p>
        </div>

        <Divider />

        <div className="conversation-container">
          <ScrollPanel className="w-auto h-screen">
            {groupedMessages.map((group, index) => (
              <div key={index} className="message-group p-mb-4">
                <small
                  className={`sender-label ${group.sender === 'Agent' ? 'agent-label' : 'human-label'
                    }`}
                >
                  {group.sender}{' '}
                  <span style={{ fontStyle: 'italic' }}>
                    [{group.messages[0]?.Tag || ''} {group.messages[0]?.AiGenDurationMs || ''}ms]
                  </span>
                </small>
                {group.messages.map((message, msgIndex) => (
                  <div
                    key={msgIndex}
                    className={`message ${group.sender === 'Agent' ? 'agent' : 'human'
                      } p-mt-1`}
                  >
                    <div className="message-bubble">
                      <p>{message.Content}</p>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </ScrollPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default TestResultDialog;
