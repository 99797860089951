// File: src/components/test/CreateTestDialog.tsx

import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { createTest } from '../../services/TestService';
import { useAuth0 } from '@auth0/auth0-react';
import { Agent } from '../../models/Agent';
import { getAgents } from '../../services/AgentService';
import { Toast } from 'primereact/toast';
import { TestConversation, TestConversationStep } from '../../models/TestConversation';
import { TestForm } from './TestForm';
import { CheckboxChangeEvent } from 'primereact/checkbox';

interface CreateTestDialogProps {
  visible: boolean;
  onHide: () => void;
  onTestCreated: () => void;
}

const CreateTestDialog: React.FC<CreateTestDialogProps> = ({ visible, onHide, onTestCreated }) => {
  const { getAccessTokenSilently } = useAuth0();
  const getEmptyTest = (): TestConversation => ({
    testId: '',
    tenantId: '',
    agentId: '',
    testName: '',
    testDescription: '',
    expectedOutcome: '',
    testType: '',
    isAutomated: true,
    isActive: true,
    createdBy: '',
    lastModifiedBy: '',
    createdDateTimeUTC: '',
    lastModifiedDateTimeUTC: '',
    testSteps: [],
  });
  const [test, setTest] = useState<TestConversation>(getEmptyTest());
  const [agents, setAgents] = useState<Agent[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const toast = React.useRef<Toast>(null);

  useEffect(() => {
    if (visible) {
      fetchAgents();
    }
  }, [visible]);

  const fetchAgents = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'openid profile email',
        },
      });
      const data = await getAgents(accessToken);
      setAgents(data);
    } catch (error) {
      console.error('Failed to fetch agents', error);
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to fetch agents.',
        life: 3000,
      });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => {
    setTest({ ...test, [field]: e.target.value });
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent, field: string) => {
    setTest({ ...test, [field]: e.checked });
  };

  const handleDropdownChange = (e: { value: any }, field: string) => {
    setTest({ ...test, [field]: e.value });
  };

  const handleTestStepChange = (steps: TestConversationStep[]) => {
    setTest({ ...test, testSteps: steps });
  };

  const handleSubmit = async () => {
    if (!test.testName || !test.agentId) {
      toast.current?.show({
        severity: 'warn',
        summary: 'Validation Error',
        detail: 'Please fill in all required fields.',
        life: 3000,
      });
      return;
    }

    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'openid profile email',
        },
      });
      await createTest(test, accessToken);
      toast.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Test created successfully.',
        life: 3000,
      });
      onTestCreated();
      onHide();
    } catch (error) {
      console.error('Failed to create test', error);
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to create test.',
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const dialogFooter = (
    <div className="flex justify-content-end">
      <Button label="Cancel" icon="pi pi-times" onClick={onHide} className="p-button-text" />
      <Button label="Create" icon="pi pi-check" onClick={handleSubmit} loading={loading} />
    </div>
  );

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="Create New Test"
      footer={dialogFooter}
      className="w-screen h-screen border-2"
      modal
    >
      <Toast ref={toast} />
      <TestForm
        test={test}
        agents={agents}
        onInputChange={handleInputChange}
        onCheckboxChange={handleCheckboxChange}
        onDropdownChange={handleDropdownChange}
        onTestStepChange={handleTestStepChange}
      />
    </Dialog>
  );
};

export default CreateTestDialog;
