// ChatClientDetails.ts

import config from "../appsettings.json";

const BASE_URL = process.env.REACT_APP_API_URL;

if (!BASE_URL) {
  throw new Error('REACT_APP_API_URL is not defined');
}

export interface ChatDetailsData {
  threadId: string;
  token: string;
  identity: string;
  endpointUrl: string;
  messageId?: string;
  botUserId?: string;
}

export const getChatDetails = async (
  useDemoMode: boolean,
  tenantId: string,
  getAccessToken: () => Promise<string>
): Promise<ChatDetailsData> => {
  try {
    // Get the access token
    const accessToken = await getAccessToken();

    const requestOptions = {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
      },
      body: JSON.stringify({ useDemoMode, tenantId }),
    };

    const response = await fetch(
      `${BASE_URL}/api/customer/startconversation`,
      requestOptions,
    );

    if (!response.ok) {
      console.error(`Failed to fetch chat thread details. Status: ${response.status}`);
      const errorText = await response.text();
      console.error(`Error details: ${errorText}`);
      throw new Error(
        `Failed to fetch chat thread details. Status: ${response.status}`,
      );
    }

    const details: ChatDetailsData = await response.json();
    console.log("ChatDetailsData: ", details);
    return details;
  } catch (error) {
    console.error("Failed to start conversation, Error: ", error);
    throw new Error("Failed to start conversation");
  }
};

export const getHelperChatDetails = async (getAccessToken: () => Promise<string>): Promise<ChatDetailsData> => {
  try {
    const accessToken = await getAccessToken();
    const getRequestOptions = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    };
    const response = await fetch(
      `${BASE_URL}/api/agent/chatassistant`,
      getRequestOptions,
    );
    if (!response.ok) {
      throw new Error(
        `Failed to fetch helper chat details. Status: ${response.status}`,
      );
    }
    const details = await response.json();
    return details;
  } catch (error) {
    console.error("Failed to retrieve chatassistant data, Error: ", error);
    throw new Error("Failed to retrieve chatassistant data");
  }
};

