// File: src/models/TestConversation.ts
export interface TestConversationStep {
    stepNumber: number;
    expectedAgentResponse: string;
    humanTestResponse: string;
    notes?: string;
}

export enum TestRunResult {
    Passed = 'Passed',
    Failed = 'Failed',
    Running = 'Running',
    NotRun = 'NotRun',
}

export interface TestConversation {
    testId: string;
    tenantId: string;
    agentId: string;
    testName: string;
    testDescription?: string;
    expectedOutcome?: string;
    testType?: string;
    isAutomated: boolean;
    isActive: boolean;
    createdBy?: string;
    lastModifiedBy?: string;
    createdDateTimeUTC: string;
    lastModifiedDateTimeUTC: string;
    testSteps: TestConversationStep[];
    lastRunDateTimeUTC?: string;
    lastRunResult?: TestRunResult; // e.g., 'Passed', 'Failed', 'Running'
}
