// // Chat.tsx: React component that displays a chat window for the user to interact with the chatbot
// Chat.tsx
import React, { useMemo, useState, useEffect, useRef, useCallback } from "react";
import {
  AzureCommunicationTokenCredential,
  CommunicationUserIdentifier,
} from "@azure/communication-common";
import {
  ChatComposite,
  fromFlatCommunicationIdentifier,
  createAzureCommunicationChatAdapter,
  ChatAdapter
} from "@azure/communication-react";
import { ErrorBoundary } from 'react-error-boundary';
import "../styles/Chat.css";
import { Button } from "primereact/button";

const DISPLAY_NAME = "Customer";

interface ChatProps {
  threadId: string;
  token: string;
  userId: string;
  endpointUrl: string;
  isOpen: boolean;
  newCallStarted: boolean;
  onChatCleared: () => void;
}

function ErrorFallback({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) {
  return (
    <div role="alert" className="error-fallback">
      <h2>Something went wrong:</h2>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

function Chat(props: ChatProps): JSX.Element {
  const { endpointUrl, userId, token, threadId, isOpen: propIsOpen, newCallStarted, onChatCleared } = props;
  const [isOpen, setIsOpen] = useState<boolean>(propIsOpen);
  const [chatKey, setChatKey] = useState(0);
  const displayName = DISPLAY_NAME;
  const chatAdapterRef = useRef<ChatAdapter | null>(null);

  const credential = useMemo(() => {
    try {
      return new AzureCommunicationTokenCredential(token);
    } catch (error) {
      console.error("Failed to construct token credential:", error);
      return null;
    }
  }, [token]);

  const chatAdapterArgs = useMemo(() => {
    if (!credential) return null;
    const userIdentifier = fromFlatCommunicationIdentifier(userId) as CommunicationUserIdentifier;
    return {
      endpoint: endpointUrl,
      userId: userIdentifier,
      displayName,
      credential,
      threadId,
    };
  }, [endpointUrl, userId, credential, threadId]);

  const initAdapter = useCallback(async () => {
    if (chatAdapterRef.current) {
      await chatAdapterRef.current.dispose();
    }
    if (chatAdapterArgs) {
      try {
        const adapter = await createAzureCommunicationChatAdapter(chatAdapterArgs);
        chatAdapterRef.current = adapter;

        adapter.on("error", (error) => {
          console.error("Chat adapter error:", error);
          // Implement reconnection logic here if needed
        });

        setChatKey(prev => prev + 1);
      } catch (error) {
        console.error("Failed to create chat adapter:", error);
        // You might want to implement a retry mechanism here
      }
    }
  }, [chatAdapterArgs]);

  useEffect(() => {
    initAdapter();

    return () => {
      if (chatAdapterRef.current) {
        chatAdapterRef.current.dispose();
      }
    };
  }, [initAdapter]);

  useEffect(() => {
    setIsOpen(propIsOpen);
  }, [propIsOpen]);

  useEffect(() => {
    if (newCallStarted) {
      console.log("New call started, creating new chat adapter");
      initAdapter();
      onChatCleared();
    }
  }, [newCallStarted, onChatCleared, initAdapter]);

  const handleError = useCallback((error: Error) => {
    console.error("Chat error:", error);
    // Implement any error handling logic here, such as showing an error message to the user
  }, []);

  if (!chatAdapterRef.current) {
    return <h3>Initializing Chat Window...</h3>;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError} onReset={initAdapter}>
      <div className="chat-container"  data-augmented-ui="tr-clip tl-clip">
        {isOpen ? (
          <div className="chat-popup">
            <div className="chat-header bg-primary max-h-1rem">
              <h4 className="ml-2">ANDR3W Live Call Log</h4>
              <button onClick={() => setIsOpen(false)} aria-label="Close chat">X</button>
            </div>
            <div className="chat-control">
              <ChatComposite
                key={chatKey}
                adapter={chatAdapterRef.current}
                options={{
                  topic: false,
                  errorBar: true,
                }}
              />
            </div>
          </div>
        ) : (
          <Button data-augmented-ui="tr-clip bl-clip" onClick={() => setIsOpen(true)}>
            Make Call [Log]
          </Button>
        )}
      </div>
    </ErrorBoundary>
  );
}

export default React.memo(Chat);
