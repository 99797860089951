// File: src/components/test/TestForm.tsx

import React from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { TestConversation, TestConversationStep } from '../../models/TestConversation';
import { Agent } from '../../models/Agent';
import { TestStepEditor } from './TestStepEditor';
import { FloatLabel } from 'primereact/floatlabel';

interface TestFormProps {
  test: TestConversation;
  agents: Agent[];
  onInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => void;
  onCheckboxChange: (e: CheckboxChangeEvent, field: string) => void;
  onDropdownChange: (e: { value: any }, field: string) => void;
  onTestStepChange: (steps: TestConversationStep[]) => void;
}

export const TestForm: React.FC<TestFormProps> = ({
  test,
  agents,
  onInputChange,
  onCheckboxChange,
  onDropdownChange,
  onTestStepChange,
}) => {
  return (
    <>
      <div className="flex flex-wrap mt-2 gap-3">
        <div className="field">
          <FloatLabel>
            <Dropdown
              id="agentId"
              className="w-25rem"
              value={test.agentId}
              options={agents}
              onChange={(e) => onDropdownChange(e, 'agentId')}
              optionLabel="agentCompanyName"
              optionValue="agentId"
              required
            />
            <label htmlFor="agentId">Agent *</label>
          </FloatLabel>
        </div>
        <div className="field">
          <FloatLabel>
            <InputText
              id="testName"
              className="w-26rem"
              value={test.testName}
              onChange={(e) => onInputChange(e, 'testName')}
              required
            />
            <label htmlFor="testName">Test Name *</label>
          </FloatLabel>
        </div>
        <div className="field">
          <FloatLabel>
            <InputText
              id="testType"
              className="w-11rem"
              value={test.testType}
              onChange={(e) => onInputChange(e, 'testType')}
            />
            <label htmlFor="testType">Test Type</label>
          </FloatLabel>
        </div>
      </div>
      <div className="flex flex-wrap mt-2 gap-3">
        <div className="field">
          <FloatLabel>
            <InputTextarea
              id="testDescription"
              className='w-30rem h-7rem'
              value={test.testDescription}
              onChange={(e) => onInputChange(e, 'testDescription')}
              rows={3}
              cols={30}
            />
            <label htmlFor="testDescription">Test Description</label>
          </FloatLabel>
        </div>
        <div className="field">
          <FloatLabel>
            <InputTextarea
              id="expectedOutcome"
              className='w-30rem h-7rem'
              value={test.expectedOutcome}
              onChange={(e) => onInputChange(e, 'expectedOutcome')}
              rows={3}
              cols={30}
            />
            <label htmlFor="expectedOutcome">Expected Outcome</label>
          </FloatLabel>
        </div>
      </div>

      <div className="flex flex-wrap mt-2 gap-3">
        <div className="field-checkbox">
          <Checkbox
            inputId="isAutomated"
            checked={test.isAutomated}
            onChange={(e) => onCheckboxChange(e, 'isAutomated')}
          />
          <label htmlFor="isAutomated">Is Automated</label>
        </div>
        <div className="field-checkbox">
          <Checkbox
            inputId="isActive"
            checked={test.isActive}
            onChange={(e) => onCheckboxChange(e, 'isActive')}
          />
          <label htmlFor="isActive">Is Active</label>
        </div>
      </div>

      <TestStepEditor steps={test.testSteps} onStepsChange={onTestStepChange} />
    </>
  );
};
