// MakeCallDetails.ts - Called by CallBoard.tsx handleCall function to make a call
import config from "../appsettings.json";

const BASE_URL = process.env.REACT_APP_API_URL;

if (!BASE_URL) {
  throw new Error('REACT_APP_API_URL is not defined');
}

export const makePhoneCall = async (
  AcsPhoneNumberE164: string,
  TargetPhoneNumber: string,
  UseDemoMode: boolean,
  UseDemoIvrMode: boolean,
  TenantId: string,
  Identity: string,
  ThreadId: string,
  Token: string,
  AnswerPromptSystemTemplate: string,
  SelectedVoice: string,
  AiName: string,
  EnableChatWindow: boolean,
  EnableStreamedResponses: boolean,
  TargetCompanyName: string,
  AzureOpenAiDeploymentName: string,
  WaitForHelloMs: string,
  InitialSilenceTimeoutMs: string,
  SpeechPauseTimeoutMs: string,
  FirstName: string,
  Email: string,
  accessToken: string
): Promise<{ success: boolean; errorCode?: string; errorMessage?: string }> => {
  const requestBody = {
    AcsPhoneNumberE164,
    TargetPhoneNumber,
    UseDemoMode,
    UseDemoIvrMode,
    TenantId,
    Identity,
    ThreadId,
    Token,
    AnswerPromptSystemTemplate,
    SelectedVoice,
    AiName,
    EnableChatWindow,
    EnableStreamedResponses,
    TargetCompanyName,
    AzureOpenAiDeploymentName,
    WaitForHelloMs,
    InitialSilenceTimeoutMs,
    SpeechPauseTimeoutMs,
    FirstName,
    Email
  };

  console.log("makePhoneCall function called");
  console.log("TenantId in request body:", requestBody.TenantId);
  //console.log("Access Token (first 20 chars):", accessToken.substring(0, 20));

  try {

    // TODO. DEBUGGING: Remove sensitive data from logs
    console.log("Attempting to make phone call");
    //console.log("Full access token:", accessToken);
    console.log("API URL:", `${BASE_URL}/api/makephonecall`);
    // console.log("Request headers:", {
    //   "Content-Type": "application/json",
    //   "Authorization": `Bearer ${accessToken}`
    // });
    // END DEBUGGING
    console.log("Request body:", JSON.stringify(requestBody, null, 2));
    console.log("Sending request to:", `${BASE_URL}/api/makephonecall`);

    const response = await fetch(`${BASE_URL}/api/makephonecall`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
      },
      body: JSON.stringify(requestBody),
    });

    //console.log("Response status:", response.status);
    //console.log("Response headers:", response.headers);

    if (!response.ok) {
      const responseText = await response.text();
      console.error("Full error response:", responseText);
      try {
        const errorData = JSON.parse(responseText);
        console.error("Parsed error data:", errorData);
      } catch (jsonError) {
        console.error("Failed to parse error response as JSON:", jsonError);
      }

      let errorMessage = responseText;

      console.error("Error response from makePhoneCall API:", errorMessage);
      return {
        success: false,
        errorCode: `HTTP${response.status}`,
        errorMessage: errorMessage,
      };
    }

    const responseData = await response.json();
    console.log("Response from makePhoneCall API:", responseData);

    return { success: true };
  } catch (error) {
    console.error("Error in makePhoneCall:", error);
    return {
      success: false,
      errorCode: "NetworkError",
      errorMessage: error instanceof Error ? error.message : "An unknown error occurred",
    };
  }
};
