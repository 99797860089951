// File: src/components/test/TestSearchBar.tsx

import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';


interface TestSearchBarProps {
  onSearch: (searchTerm: string) => void;
}

const TestSearchBar: React.FC<TestSearchBarProps> = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <div className="p-inputgroup">
      <InputText
        placeholder="Search Tests"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <span className="p-inputgroup-addon">
        <i className="pi pi-search" />
      </span>
    </div>
  );
};

export default TestSearchBar;
