// src/utils/auth.ts
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect, useCallback } from 'react';

import { jwtDecode } from 'jwt-decode';

export const useAuth = () => {
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const [tenantId, setTenantId] = useState<string | null>(null);

  const fetchTenantId = useCallback(async () => {
    if (!isAuthenticated || !user) {
      console.log("User is not authenticated or user object is not available");
      return;
    }

    try {
      console.log("Fetching tenant ID...");
      let tenantIdFromToken: string | undefined;

      // Attempt to get the access token
      let accessToken;
      try {
        accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: "read:user_metadata offline_access",
          },
        });
        console.log("Access token received:", accessToken);
      } catch (error: any) {
        console.error("Error obtaining access token:", error.message, error);
      }

      // Decode the token if we have it
      if (accessToken) {
        const decodedToken = jwtDecode<any>(accessToken);
        console.log("Decoded token:", decodedToken);
        tenantIdFromToken = decodedToken['https://api.andr3w.ai/tenant_id'];
      }

      // Fallback to user object
      if (!tenantIdFromToken && user['https://api.andr3w.ai/tenant_id']) {
        tenantIdFromToken = user['https://api.andr3w.ai/tenant_id'];
        console.log("Tenant ID found in user object:", tenantIdFromToken);
      }

      if (tenantIdFromToken) {
        console.log("Tenant ID found:", tenantIdFromToken);
        setTenantId(tenantIdFromToken);
        localStorage.setItem("tenant_id", tenantIdFromToken);
      } else {
        console.error("Tenant ID not found in token or user object.");
        setTenantId(null);
      }
    } catch (e: any) {
      console.error("Error fetching tenant ID:", e.message, e);
      setTenantId(null);
    }
  }, [getAccessTokenSilently, isAuthenticated, user]);




  useEffect(() => {
    fetchTenantId();
  }, [isAuthenticated, fetchTenantId]);

  const apiCall = async (url: string, options: RequestInit = {}) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error("API call failed:", error);
      throw error;
    }
  };

  return { apiCall, tenantId, refetchTenantId: fetchTenantId };
};




// // src/utils/auth.ts
// import { useAuth0 } from "@auth0/auth0-react";
// import { useState, useEffect, useCallback } from 'react';

// export const useAuth = () => {
//   const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
//   const [tenantId, setTenantId] = useState<string | null>(null);

//   const fetchTenantId = useCallback(async () => {
//     if (!isAuthenticated || !user) {
//       console.log("User is not authenticated or user object is not available");
//       return;
//     }

//     try {
//       console.log("Fetching tenant ID...");
//       let tenantIdFromToken: string | undefined;

//       // Attempt to get the access token
//       let accessToken;
//       try {
//         accessToken = await getAccessTokenSilently({
//           authorizationParams: {
//             audience: process.env.REACT_APP_AUTH0_AUDIENCE,
//             scope: "read:user_metadata offline_access",
//           },
//         });
//         console.log("Access token received:", accessToken.substring(0, 20) + "...");
//       } catch (error: any) {
//         console.error("Error obtaining access token:", error.message, error);
//       }

//       // Decode the token if we have it
//       if (accessToken) {
//         const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
//         console.log("Decoded token:", decodedToken);
//         tenantIdFromToken = decodedToken['https://api.andr3w.ai/tenant_id'];
//       }

//       // Fallback to user object
//       if (!tenantIdFromToken && user['https://api.andr3w.ai/tenant_id']) {
//         tenantIdFromToken = user['https://api.andr3w.ai/tenant_id'];
//         console.log("Tenant ID found in user object:", tenantIdFromToken);
//       }

//       if (tenantIdFromToken) {
//         console.log("Tenant ID found:", tenantIdFromToken);
//         setTenantId(tenantIdFromToken);
//         localStorage.setItem("tenant_id", tenantIdFromToken);
//       } else {
//         console.error("Tenant ID not found in token or user object.");
//         setTenantId(null);
//       }
//     } catch (e: any) {
//       console.error("Error fetching tenant ID:", e.message, e);
//       setTenantId(null);
//     }
//   }, [getAccessTokenSilently, isAuthenticated, user]);




//   useEffect(() => {
//     fetchTenantId();
//   }, [isAuthenticated, fetchTenantId]);

//   const apiCall = async (url: string, options: RequestInit = {}) => {
//     try {
//       const token = await getAccessTokenSilently();
//       const response = await fetch(url, {
//         ...options,
//         headers: {
//           ...options.headers,
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }
//       return await response.json();
//     } catch (error) {
//       console.error("API call failed:", error);
//       throw error;
//     }
//   };

//   return { apiCall, tenantId, refetchTenantId: fetchTenantId };
// };


