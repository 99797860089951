// File: src/services/TestService.ts

import { TestConversation } from '../models/TestConversation';
import { TestConversationResult } from '../models/TestConversationResult';


const API_BASE_URL = process.env.REACT_APP_API_URL;
// Remove after debugging
console.log('API_BASE_URL:', API_BASE_URL);

export const getAllTests = async (
    accessToken: string
): Promise<TestConversation[]> => {
    const response = await fetch(`${API_BASE_URL}/api/tests`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    if (!response.ok) {
        throw new Error('Failed to fetch tests.');
    }
    const data = await response.json();
    return data;
};

export const getTestsByAgentId = async (
    agentId: string,
    accessToken: string
): Promise<TestConversation[]> => {
    const response = await fetch(`${API_BASE_URL}/api/tests?agentId=${agentId}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    if (!response.ok) {
        throw new Error('Failed to fetch tests.');
    }
    const data = await response.json();
    return data;
};


export const createTest = async (test: TestConversation, accessToken: string): Promise<void> => {
    console.log('Payload:', JSON.stringify(test));
    const API_BASE_URL = process.env.REACT_APP_API_URL;

    // Create a new object with only the properties the server expects
    const testToSend = {
        agentId: test.agentId,
        testName: test.testName,
        testDescription: test.testDescription,
        expectedOutcome: test.expectedOutcome,
        testType: test.testType,
        isAutomated: test.isAutomated,
        isActive: test.isActive,
        testSteps: test.testSteps
    };

    console.log('Payload:', JSON.stringify(testToSend));

    const response = await fetch(`${API_BASE_URL}/api/tests`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(testToSend),
    });
    if (!response.ok) {
        throw new Error('Failed to create test: ' + response.statusText + ' ' + response.url + ' ' + response.status + ' ' + response.type);
    }
};

export const createTestFromCallId = async (
    callId: string,
    agentId: string,
    accessToken: string
) => {
    const response = await fetch(`${API_BASE_URL}/api/tests/create-from-call-id`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ callId, agentId }),
    });
    if (!response.ok) {
        throw new Error('Failed to create test from call ID.');
    }
    return await response.json();
};


export const updateTest = async (test: TestConversation, accessToken: string): Promise<void> => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;
    const response = await fetch(`${API_BASE_URL}/api/tests/${test.testId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(test),
    });
    if (!response.ok) {
        throw new Error('Failed to update test.');
    }
};

export const deleteTest = async (testId: string, accessToken: string): Promise<void> => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;
    const response = await fetch(`${API_BASE_URL}/api/tests/${testId}`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    if (!response.ok) {
        throw new Error('Failed to delete test.');
    }
};

export const runTests = async (
    testIds: string[],
    phoneNumber: string,
    accessToken: string
): Promise<void> => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;
    const response = await fetch(`${API_BASE_URL}/api/tests/run`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ testIds, phoneNumber }),
    });
    if (!response.ok) {
        throw new Error('Failed to run tests.');
    }
};

export const getTestResult = async (
    testId: string,
    accessToken: string
): Promise<TestConversationResult> => {
    const API_BASE_URL = process.env.REACT_APP_API_URL;
    const response = await fetch(`${API_BASE_URL}/api/tests/${testId}/results/latest`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    if (!response.ok) {
        const errorDetail = await response.text();
        throw new Error(`Failed to fetch test result: ${errorDetail}`);
    }
    const data = await response.json();
    return data;
};





